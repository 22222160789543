// Глобальные настройки
const Settings = {
	errorMessage: 'Упс! Что-то пошло не так...', // сообщение об ошибке для пользователя
	newStagesOrderCountOnPage: 1000, // кол-во заказов на странице для стадий с новыми заказами (по сколько подгружать)
	oldStagesOrderCountOnPage: 20, // кол-во заказов на странице для стадий со старыми заказами (по сколько подгружать)
	startupPagePath: '/Main/Orders/g2', // адрес стартовой "страницы"
	orderGroupMenuTimeout: 120000, // период обновления меню групп заказов в миллисекундах
	notificationsPanelTimeout: 600000, // период обновления панели уведомлений хидера в миллисекундах
	walletTimeout: 600000, // период обновления панели рабочей информации в хидере в миллисекундах
	minScreenWidthTablet: 768, // минимальная ширина экрана отображения для планшета
	minScreenWidthDesktop: 1080, // минимальная ширина экрана отображения для компьютера
	minScreenWidthWideDesktop: 1440, // минимальная ширина экрана отображения для компьютера с широким экраном
	googleDocsViewerLink: 'https://docs.google.com/viewer?url=', // ссылка на просмотрщик файлов от Google
	maxPersonalDataFileSize: 10485760, // максимальный размер файла в байта, загружаемый для отправки ПДА (иностранцев и ИП)  (10 * 1024 * 1024 = 10485760 байт)
	maxFinalWorkFileSize: 31457280, // максимальный размер файлов в байтах, загружаемых в окне отправки итоговой работы (30 * 1024 * 1024 = 31457280 байт)
	maxChatAttachmentFileSize: 31457280, // максимальный размер файлов в байтах, прикрепляемых к сообщению в чате (30 * 1024 * 1024 = 31457280 байт)
	orderGroupsFiltersVersion: 6, // версия фильтров групп заказов (набор фильтров на страницах групп заказов)
	headerFixingOrderAmount: 1, // версия значения отображения суммы по заказам в хидере
	filtersKeySharedPart: 'mwfilters-', // общая часть в названии ключей в localstorage для хранения последних значений пользовательских фильтров для групп заказов
	signalRHubURL: 'https://im.assistmw.ru', // хаб SignalR для мгновенного обмена сообщениями
	siganlRReconnectionDelay: 10000, // задержка реконкта к хабу SignalR в милисекундах
	suggestionListLength: 5, // количество элементов в списке елементов, предложенных по части названия
	messageCount: 15, // количество сообщений, которое будет загружено в чат за один раз
	defaultSource: 'default', // источник регистрации анкет автора по умолчанию
	emailRegExp: /^([a-z0-9_.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/i, // регулярное выражение проверки корректности электронной почты
	phoneRegExp: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/i, // регулярное выражение проверки корректности номера телефона
	googleDocsViewableMaterialExtensions: [
		'.doc',
		'.docx',
		'.xls',
		'.xlsx',
		'.ppt',
		'.pptx',
		'.pdf',
		'.pages',
		'.ai',
		'.psd',
		'.tiff',
		'.dxf',
		'.svg',
		'.eps',
		'.ps',
		'.ttf',
		'.xps',
		'.rtf',
		'.odt',
		'.sxw',
		'.ssv',
		'.sxc',
		'.ods',
		'.sxi',
		'.odp',
		'.wpd',
	], // расширения материалов, доступных для просмотра в Google Docs
	executionStandartFileLink:
		'https://docs.google.com/document/d/1qDdBo23tySmhNezoxsMno4_PAgjukS2ElAfqku_EzhY/edit#', // ссылка на файл стандартов выполнения
	telegramUrl: 'https://t.me/', // основа ссылки на чат в телеграме
	whatsAppUrl: 'https://wa.me/', // основа ссылки на чат в ватсапп
	searchBarCharMaxCount: 128, // максимальное количество символов в строке поиска
	searchBarCharMinCount: 4, // минимальное количество символов в строке поиска
	formRegistrationChecksLink: "https://yookassa.ru/self-employed-boarding?agentId=201147&success_url=https://partner.multiwork.org/Main/Profile/PaymentInfo?formType=1&fail_url=https://partner.multiwork.org/Main/Profile/PaymentInfo", // ссылка на форму регистрации чеков
	formRegistrationCard: "https://yookassa.ru/cardauth?url_error=https://partner.multiwork.org/Main/Profile/PaymentInfo&url_success=https://partner.multiwork.org/Main/Profile/PaymentInfo?formType=1&identify=true", // форма добавления карты
	agentId: 206741, // идентификатор платежного шлюза ЮМани
}

export default Settings
